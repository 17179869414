import '../scripts/jquery.magnific-popup.min.js';
import '../styles/index.scss';
import '../styles/magnific-popup.css';

import $ from 'jquery';
window.$ = $;

import slick from "slick-carousel";
//import '../scripts/main.js';

//import '../quienes-somos.html';


$(document).ready(function(){

  /* home slider */
  $('.header > .slider > ul').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000,
    pauseOnHover: false,
    pauseOnFocus: false,
    lazyLoad: 'progressive',
    responsive: [
        {
            breakpoint: 1100,
            settings: {
                adaptiveHeight: true
            }
        }
    ]
  });

  $('a.video').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
      type: 'iframe',
      src: $(this).attr('href'),
      preloader: false,
      fixedContentPos: false,
    });
  });

    /* Menu */

    function toggleClassMenu() {
        myMenu.classList.add("menu-responsive--animatable");   
        if(!myMenu.classList.contains("menu-responsive--visible")) {       
            myMenu.classList.add("menu-responsive--visible");
        } else {
            myMenu.classList.remove('menu-responsive--visible');       
        }   
    }

    function OnTransitionEnd() {
        myMenu.classList.remove("menu-responsive--animatable");
    }

    var myMenu = document.querySelector(".menu-responsive");
    var oppMenu = document.querySelector(".main-menu-icon");
    myMenu.addEventListener("transitionend", OnTransitionEnd, false);
    oppMenu.addEventListener("click", toggleClassMenu, false);
    myMenu.addEventListener("click", toggleClassMenu, false);

  /* smooth scroll */
  var flag_scroll = 0;
  var value_scroll = 69;
  $(function() {
      $('a[href*="#"]:not([href="#"])').on('click', function(e) {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              e.preventDefault();
              var target = $(this.hash);
              var objeto = $(this);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

              if (target.length) {
                  $('html,body').stop().animate({
                      scrollTop: target.offset().top -  value_scroll
                  }, 1000);
                  var forx = target.attr('for');
                  $('#'+forx).attr({'checked': 'checked'});
              }
          }
      });
  });

});
